import React from 'react'
import { Container } from '../../components/elements'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import ERPForm from '../../components/forms/ERPSignUp'
import Newsletter from '../../components/Newsletter'

import NotFound from '../404'

const ERPContactPage = () => {
  const breadcrumbs = [
    {
      name: 'ERP',
      uri: '/erp',
    },
    {
      name: 'Contact - ERP',
      uri: '/erp/contact',
    },
  ]

  return <NotFound />

  // return (
  //   <Page className={`p-sign-up p-forManufacturers has-fingerPrints`}>
  //     <SEO title="Contact - ERP" />
  //     <Schema breadcrumbs={breadcrumbs} />

  //     <section className="c-section c-section--pageHeader">
  //       <Container className="c-section__outer">
  //         <div className="c-section__inner">
  //           <div className="c-pageHeader">
  //             <div className="row">
  //               <div className="col-12 col-lg-3">
  //                 <span className="c-pageHeader__title">
  //                   <strong>Erudus ERP</strong>
  //                 </span>
  //               </div>
  //               <div className="col-12 col-lg-9">
  //                 <h1 className="c-pageHeader__intro">ERP contact form</h1>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </Container>
  //     </section>

  //     <section className="c-section c-section--contact-us">
  //       <div className="c-section__outer container">
  //         <div className="c-section__inner pt-0 pb-1">
  //           <div className="c-sectionTitle">
  //             <div className="row">
  //               <div className="col-12 col-lg-8 offset-lg-3">
  //                 <ERPForm formName="ERP Sign Up Form" />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>

  //     <Newsletter />
  //   </Page>
  // )
}

export default ERPContactPage
